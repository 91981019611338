<template>
  <svg
    fill="none"
    height="160"
    viewBox="0 0 200 160"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M135.844 20H109C106.5 20 104.094 20.9375 102.25 22.625L71.5312 50.75C71.5 50.7812 71.4688 50.8438 71.4375 50.875C66.25 55.75 66.3438 63.5312 70.7812 68.375C74.75 72.7188 83.0938 73.875 88.3125 69.2188C88.3438 69.1875 88.4062 69.1875 88.4375 69.1562L113.406 46.2813C115.438 44.4375 118.625 44.5625 120.469 46.5938C122.344 48.625 122.188 51.7812 120.156 53.6562L112 61.125L157.5 98.0625C158.406 98.8125 159.219 99.625 159.969 100.469V40L142.906 22.9375C141.062 21.0625 138.5 20 135.844 20ZM170 40.0625V110.031C170 115.562 174.469 120.031 180 120.031H200V40.0625H170ZM185 110.031C182.25 110.031 180 107.781 180 105.031C180 102.281 182.25 100.031 185 100.031C187.75 100.031 190 102.281 190 105.031C190 107.781 187.75 110.031 185 110.031ZM0 120H20C25.5312 120 30 115.531 30 110V40.0625H0V120ZM15 100.031C17.75 100.031 20 102.281 20 105.031C20 107.781 17.75 110.031 15 110.031C12.25 110.031 10 107.781 10 105.031C10 102.25 12.25 100.031 15 100.031ZM151.219 105.844L104.562 67.9688L95.1875 76.5625C85.9062 85.0312 71.6875 84.2188 63.4062 75.1875C55 66 55.6562 51.7812 64.7812 43.4062L90.3438 20H64.1562C61.5 20 58.9688 21.0625 57.0938 22.9375L40 40V109.969H45.7188L74 135.562C82.5625 142.531 95.1562 141.219 102.125 132.656L102.188 132.594L107.781 137.438C112.75 141.5 120.094 140.719 124.125 135.75L133.938 123.688L135.625 125.062C139.906 128.531 146.219 127.906 149.688 123.594L152.656 119.938C156.156 115.625 155.5 109.344 151.219 105.844Z"
      fill="currentColor"
    />
  </svg>
</template>
