<template>
  <svg
    fill="none"
    height="140"
    viewBox="0 0 140 140"
    width="140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.05078 68.7217L70.0001 93.6331L136.95 68.7217V118.544C136.95 125.424 131.373 131 124.494 131H15.5065C8.62738 131 3.05078 125.424 3.05078 118.544V68.7217Z"
      fill="currentColor"
    />
    <path
      d="M136.949 58.601L69.9993 81.9556L3.05049 58.601L3.05049 40.6959C3.0505 33.8168 8.62711 28.2402 15.5062 28.2402L124.493 28.2402C131.373 28.2402 136.949 33.8168 136.949 40.6959L136.949 58.601Z"
      fill="currentColor"
    />
    <path
      d="M45.0889 17.3418C45.0889 12.1825 49.2713 8 54.4306 8H85.5699C90.7292 8 94.9117 12.1825 94.9117 17.3418V28.2405H82.456V22.0127C82.456 19.433 80.3647 17.3418 77.7851 17.3418H63.7724C60.3329 17.3418 57.5446 20.1301 57.5446 23.5696V28.2405H45.0889V17.3418Z"
      fill="currentColor"
    />
  </svg>
</template>
